import { type ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { Check, KeyboardArrowDown } from '@mui/icons-material';

import { useClientSize } from '~/hooks/use-client-size';

import styles from './styles.module.css';

import Portal from './portal';
import Required from './required';
import {
  DROPDOWN_HEIGHT,
  EmptyState,
  GroupLabel,
  Icon,
  LabelButton,
  ListWrapper,
  SearchInput,
  SelectWrapper,
} from './select-search';
import { Note, Overlay, SpanLabel } from './styled';
import { withStyledComponents } from './with-styled-component';
import { debounce } from 'lodash';
import classNames from 'classnames';

const Chip = withStyledComponents(styles.SelectChip, 'span');
const CheckboxVisual = withStyledComponents(styles.SelectCheckboxVisual, 'span', {
  hasBackground: styles.hasBackground,
});

const MultiSelectLabelButton = withStyledComponents(styles.MultiSelectLabelButton, LabelButton, {
  hasChips: styles.hasChips,
  large: styles.large,
});

type SelectOption<T extends unknown = string> = { value: T; label: string; [key: string]: any };
type SelectGroup<T extends unknown = string> = { label: string; options: SelectOption<T>[] };

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
export function isGroup<T extends unknown = string>(item: unknown): item is SelectGroup<T> {
  return isObject(item) && typeof item['options'] === 'object';
}
export function isOption<T extends unknown = string>(item: unknown): item is SelectOption<T> {
  return isObject(item) && !item.options;
}

const getSelectedOptions = <T extends unknown = string[]>(val: T[], options: (SelectOption<T> | SelectGroup<T>)[]) => {
  const selectedOptions = [];

  for (let index = 0; index < options.length; index++) {
    const opt = options[index];

    if (isGroup<T>(opt)) {
      const selectedOpt = opt.options.find(({ value }) =>
        val.map((t) => JSON.stringify(t)).includes(JSON.stringify(value)),
      );
      if (selectedOpt) {
        selectedOptions.push(selectedOpt);
      }
    } else if (isOption<T>(opt) && val.map((t) => JSON.stringify(t)).includes(JSON.stringify(opt.value))) {
      selectedOptions.push(opt);
    }
  }

  return selectedOptions;
};

export const MultiSelectSearch = <T extends unknown = string>({
  options,
  onChange,
  value,
  placeholder,
  searchPlaceholder,
  small,
  large,
  overlayedLabel,
  required,
  fullWidth,
  label,
  note,
  search,
  disabled,
}: {
  options: (SelectOption<T> | SelectGroup<T>)[];
  onChange: (opt: SelectOption<T>[]) => void;
  value: T[];
  disabled?: boolean;
  label?: string | JSX.Element;
  note?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  small?: boolean;
  large?: boolean;
  overlayedLabel?: boolean;
  search?: boolean;
  required?: boolean;
  fullWidth?: boolean;
}) => {
  const { clientHeight } = useClientSize();
  const [searchString, setSearchString] = useState('');
  const [localValue, setLocalValue] = useState<T[]>(value);
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const selectedOptions = getSelectedOptions(localValue, options);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    setSearchString('');
  }, [showOptions]);

  const handleSelection = (selectedVal: T) => {
    if (disabled) {
      return;
    }
    const newVal = localValue.map((t) => JSON.stringify(t)).includes(JSON.stringify(selectedVal))
      ? localValue.filter((val) => JSON.stringify(val) !== JSON.stringify(selectedVal))
      : [...localValue, selectedVal];

    const newSelectedOption = getSelectedOptions(newVal, options);
    onChange(newSelectedOption);
    setLocalValue(newVal);
  };

  const debouncedHideHandler = useMemo(() => debounce((val: boolean) => setShowOptions(val), 100), [setShowOptions]);

  const handleToggleOptions = () => {
    if (disabled) {
      return;
    }
    debouncedHideHandler.cancel();
    setShowOptions(!showOptions);
  };

  const showAbove = useMemo(() => {
    if (showOptions) {
      const rect = selectRef.current?.getBoundingClientRect();
      const spaceBelow = (window.innerHeight || 0) - (rect?.bottom || 0);

      if (spaceBelow < DROPDOWN_HEIGHT) {
        return true;
      } else {
        return false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, clientHeight]);

  const renderOption = (opt: SelectOption<T>) => {
    const checked = localValue.map((t) => JSON.stringify(t)).includes(JSON.stringify(opt.value));

    return (
      <li key={JSON.stringify(opt.value)}>
        <button
          disabled={disabled || opt.disabled}
          className={`${checked && 'selected'}`}
          onClick={() => handleSelection(opt.value)}
        >
          <CheckboxVisual hasBackground={checked}>{checked && <Check />}</CheckboxVisual>
          {opt.label}
        </button>
      </li>
    );
  };

  const allSelected = useMemo(() => {
    const allAvailableValues = options.reduce(
      (acc: T[], opt) => [
        ...acc,
        ...(isGroup(opt)
          ? opt.options.filter((o) => !o.disabled).map((o) => (o as SelectOption).value as T)
          : (opt as SelectOption).disabled
            ? []
            : [(opt as SelectOption).value as T]),
      ],
      [],
    );
    return allAvailableValues.length > 0 && allAvailableValues.length === localValue.length;
  }, [localValue]);
  const handleSelectAll = () => {
    const newSelection = allSelected
      ? []
      : options.reduce(
          (acc: T[], opt) => [
            ...acc,
            ...(isGroup(opt)
              ? opt.options.filter((o) => !o.disabled).map((o) => (o as SelectOption).value as T)
              : (opt as SelectOption).disabled
                ? []
                : [(opt as SelectOption).value as T]),
          ],
          [],
        );

    const newSelectedOption = getSelectedOptions(newSelection, options);
    onChange(newSelectedOption);
    setLocalValue(newSelection);
  };

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        option.label?.toLowerCase().includes(searchString?.toLowerCase()) ||
        (isOption(option) &&
          option?.value &&
          option.value.toString().toLowerCase().includes(searchString?.toLowerCase())),
    );
  }, [options, searchString]);

  const pos = selectRef.current?.getBoundingClientRect();
  const listPos = listRef.current?.getBoundingClientRect();
  const optionsTop = !showAbove
    ? (pos?.bottom || 0) - 2
    : (pos?.bottom || 0) -
      (pos?.height || 0) +
      2 -
      ((listPos?.height || 0) < DROPDOWN_HEIGHT ? listPos?.height || 0 : DROPDOWN_HEIGHT);

  return (
    <div className={classNames({ [styles.isOverlayedLabel]: overlayedLabel })}>
      <SpanLabel
        className={classNames({ [styles.isOverlayedLabelSelect]: overlayedLabel })}
        isOverlayed={overlayedLabel}
      >
        {label}
        {required && <Required />}
      </SpanLabel>

      {note && <Note>{note}</Note>}
      <SelectWrapper ref={selectRef} small={small} fullWidth={fullWidth}>
        {search && showOptions ? (
          <SearchInput
            autoFocus
            type="text"
            small={small}
            large={large}
            showAbove={showAbove}
            className={`${showOptions && 'active'}`}
            placeholder={searchPlaceholder}
            value={searchString}
            onChange={({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => setSearchString(value)}
          />
        ) : (
          <MultiSelectLabelButton
            hasChips={!!selectedOptions?.length}
            small={small}
            large={large}
            showAbove={showAbove}
            isPlaceholder={!selectedOptions?.length}
            className={`${showOptions ? 'active' : ''}${disabled ? ' disabled' : ''} select-button`}
            onClick={handleToggleOptions}
          >
            {selectedOptions?.length
              ? selectedOptions?.map((opt) => <Chip key={JSON.stringify(opt.value)}>{opt.label}</Chip>)
              : placeholder}
            <Icon>
              <KeyboardArrowDown />
            </Icon>
          </MultiSelectLabelButton>
        )}
        <Portal>
          <ListWrapper
            ref={listRef}
            style={{
              opacity: showOptions ? 1 : 0,
              visibility: showOptions ? 'visible' : 'hidden',
              position: 'fixed',
              top: optionsTop < 0 ? 0 : optionsTop,
              maxHeight: optionsTop < 0 ? DROPDOWN_HEIGHT + optionsTop : DROPDOWN_HEIGHT,
              left: pos?.left,
              width: pos?.width,
            }}
          >
            {filteredOptions.length ? (
              <>
                {!searchString && (
                  <li>
                    <button disabled={disabled} className={`${allSelected && 'selected'}`} onClick={handleSelectAll}>
                      <CheckboxVisual hasBackground={allSelected}>{allSelected && <Check />}</CheckboxVisual>
                      {!allSelected ? 'Select all' : 'Unselect all'}
                    </button>
                  </li>
                )}
                {filteredOptions.map((opt, index) =>
                  isGroup(opt) ? (
                    <li key={opt.label}>
                      <GroupLabel>{opt.label}</GroupLabel>{' '}
                      <ul>
                        {opt.options.map((childOpt) => (
                          <Fragment key={JSON.stringify((childOpt as SelectOption).value)}>
                            {renderOption(childOpt as SelectOption<T>)}
                          </Fragment>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <Fragment key={JSON.stringify((opt as SelectOption).value)}>
                      {renderOption(opt as SelectOption<T>)}
                    </Fragment>
                  ),
                )}
              </>
            ) : (
              <EmptyState>Could not find what you searched</EmptyState>
            )}
          </ListWrapper>
          {showOptions && (
            <Overlay
              showMobile
              style={{ background: 'none', zIndex: 99999998 }}
              onClick={() => setShowOptions(false)}
            />
          )}
        </Portal>
      </SelectWrapper>
    </div>
  );
};
